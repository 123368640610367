<template>
    <div>
        <PageLoader v-bind:storage="appLoading" />
        <ServerError v-if="ServerError" />
        <div style="background-color: #ebf0ff">
            <v-layout wrap justify-center pt-5 pb-10>
                <v-flex xs12 sm12 md10>
                    <v-layout wrap pb-6>
                        <v-flex xs10 sm10 md6 text-left px-0 pb-5>
                            <span style="font-family: sedanRegular; font-size: 18px; cursor: pointer;">
                                Block Crawler - Transaction level</span>
                        </v-flex>
                        <v-flex xs2 sm2 md2 text-left>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" color="green" size="30px"
                                        @click.native="$router.push('/blockChainView?id=' + asset.blockId + '&block=' + asset.blockNumber)">mdi-arrow-left-circle</v-icon>
                                </template>
                                <span>Back to transaction level</span>
                            </v-tooltip></v-flex>
                    </v-layout>
                    <v-tabs v-model="tabValue" color="#7CB9E8" grow dark center-active centered :show-arrows="true"
                        active-class="activ" class="nonactiv" background-color="#ebf0ff" style="font-family: robotoRegular">
                        <v-tab style="color: #000" dark href="#tab-1">public fields</v-tab>
                        <v-tab style="color: #000" dark href="#tab-2">public files</v-tab>
                        <v-tab v-if="privateView.isPrivateFilesAccessible == true" style="color: #000" dark href="#tab-3">
                            private files</v-tab>
                        <v-tab-item value="tab-1">
                            <v-card flat style="background-color: #ebf0ff">
                                <PublicField :asset="asset" />
                            </v-card>
                        </v-tab-item>
                        <v-tab-item value="tab-2">
                            <v-card flat style="background-color: #ebf0ff">
                                <PublicFiles value="tab-2" :asset="asset" />
                            </v-card>
                        </v-tab-item>

                        <v-tab-item value="tab-3" :disabled="privateView.isPrivateFilesAccessible == false">
                            <v-card flat style="background-color: #ebf0ff"
                                v-if="privateView.isPrivateFilesAccessible == true">
                                <PrivateFiles value="tab-3" :asset="asset" />
                            </v-card>
                            <v-card flat style="background-color: #ebf0ff" height="580px" v-else>
                                <v-layout wrap justify-center py-16>
                                    <v-flex xs12 pt-10>
                                        <span style="font-family: sedanRegular; font-size: 20px">You don't have permissions
                                            to view the private files</span>
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </v-tab-item>
                    </v-tabs>
                </v-flex>
            </v-layout>
        </div>
    </div>
</template>
  
<script>
import PublicField from "./publicField";
import PublicFiles from "./publicFiles";
import PrivateFiles from "./privateFiles";
import axios from "axios";
export default {
    components: {
        PublicField,
        PublicFiles,
        PrivateFiles,
    },
    data: () => ({
        appLoading: false,
        ServerError: false,
        tabValue: "tab-1",
        items: ["public fields", "public files", " private files"],
        asset: [],
        privateView: [],
        isDisabled: false
    }),
    mounted() {
        this.getDataV1();
    },
    methods: {
        // getData() {
        //     this.appLoading = true;
        //     axios({
        //         url: "/asset/view/" + this.$route.query.id,
        //         method: "GET",
        //         headers: {
        //             token: localStorage.getItem("token"),
        //         },
        //     })
        //         .then((response) => {
        //             this.appLoading = false;
        //             this.asset = response.data.data;
        //             this.privateView = response.data
        //         })
        //         .catch((err) => {
        //             this.appLoading = false;
        //             this.ServerError = true;
        //             console.log(err);
        //         });
        // },
        getDataV1() {
            this.appLoading = true;
            axios({
                url: "/block/transaction/view",
                method: "GET",
                headers: {
                    token: localStorage.getItem("token"),
                },
                params:{
                    blockNumber :this.$route.query.block,
                    txnNumber :this.$route.query.txnNumber
                }
            })
                .then((response) => {
                    this.appLoading = false;
                    this.asset = response.data.data;
                    this.privateView = response.data
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },
        back() {
            this.$router.push("/blockChainView")
        },
    },
};
</script>
<style scoped>
.activ {
    color: black !important;
    font-family: robotoRegular !important;
}

.nonactiv {
    color: #acacac !important;
    font-family: robotoRegular !important;
}
</style>
  