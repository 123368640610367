<template>
  <div style="height: 800px">
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#7CB9E8" top :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center" style="font-size: 18px; font-family: montserratSemiBold">{{ msg
        }}</v-flex>
        <v-flex text-right>
          <v-btn :ripple="false" text @click="showSnackBar = false">
            <span style="font-size: 18px; font-family: montserratExtraBold">Ok
            </span>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center py-5>
      <v-flex xs12 sm12 text-left>
        <v-layout wrap pb-16>
          <v-flex xs9 sm10 px-5>
            <span style="font-family: sedanRegular; color: #000000; font-size: 18px">Public Files</span>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs3 sm2 v-if="asset.publicFiles">
            <a style="text-decoration: none" target="_blank" download class="downlink" :href="
              baseURL +
              '/asset/publicFiles/view?id=' +
              asset._id +
              '&token=' +
              tok
            ">
              <span style="font-family: sedanRegular; color: black">Download <v-icon color="#7CB9E8">mdi-download</v-icon>
              </span>
            </a>
          </v-flex>
          <v-layout wrap justify-center pa-4>
            <v-flex xs12 sm12 md12 pb-5>
              <!-- <span
                      style="
                        font-family: montserratSemiBold;
                        color: #000000;
                        font-size: 14px;
                      "
                      >File Name</span
                    > -->
              <v-layout wrap v-if="asset.publicFilesHashes.length > 0">
                <v-flex xs12 v-for="(publicName, p) in asset.publicFilesHashes" :key="p" pa-1>
                  <v-card outlined>
                    <v-layout wrap pa-2>
                      <v-flex xs2 sm2 pt-1>
                        <span style="
                              font-family: montserratSemiBold;
                              color: #000000;
                              font-size: 14px;
                            ">File Name :</span>
                        <!-- <v-icon color="#68c0e8">mdi-file-multiple</v-icon> -->
                      </v-flex>
                      <v-flex xs10 sm10 text-left>
                        <v-card outlined>
                          <v-layout wrap>
                            <v-flex xs10 md11 text-left pa-2 style="background-color: #d6d1c3">
                              <v-layout wrap>
                                <v-flex xs12>
                                  <span :style="{
                                    'font-size':
                                      $vuetify.breakpoint.name == 'xs'
                                        ? '10px'
                                        : $vuetify.breakpoint.name == 'sm'
                                          ? '13px'
                                          : $vuetify.breakpoint.name == 'md'
                                            ? '10px'
                                            : $vuetify.breakpoint.name == 'lg'
                                              ? '12px'
                                              : '12px',
                                  }" style="font-family: poppinssemibold">
                                    {{ publicName.fileName }}</span>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-divider vertical></v-divider>
                            <v-flex xs2 sm1 md1 text-center align-self-center>
                              <v-icon @click.stop.prevent="copyImageUrl2(p)">
                                mdi-checkbox-multiple-blank-outline
                              </v-icon>
                              <input type="hidden" :id="'testing-code2' + p" :value="publicName.fileName" />
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap pa-2>
                      <v-flex xs2 sm2 pt-1>
                        <span style="
                              font-family: montserratSemiBold;
                              color: #000000;
                              font-size: 14px;
                            ">Hash (SHA256) :</span>
                        <!-- <v-icon color="#68c0e8">mdi-pound-box</v-icon> -->
                      </v-flex>
                      <v-flex xs10 sm10 text-left>
                        <v-card outlined>
                          <v-layout wrap>
                            <v-flex xs10 md11 text-left pa-2 style="background-color: #d6d1c3">
                              <v-layout wrap>
                                <v-flex xs12>
                                  <span :style="{
                                    'font-size':
                                      $vuetify.breakpoint.name == 'xs'
                                        ? '10px'
                                        : $vuetify.breakpoint.name == 'sm'
                                          ? '13px'
                                          : $vuetify.breakpoint.name == 'md'
                                            ? '10px'
                                            : $vuetify.breakpoint.name == 'lg'
                                              ? '12px'
                                              : '12px',
                                  }" style="font-family: poppinssemibold">
                                    {{ publicName.fileHash }}</span>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-divider vertical></v-divider>
                            <v-flex xs2 sm1 md1 text-center align-self-center>
                              <v-icon @click.stop.prevent="copyImageUrl3(p)">
                                mdi-checkbox-multiple-blank-outline
                              </v-icon>
                              <input type="hidden" :id="'testing-code3' + p" :value="publicName.fileHash" />
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
              <v-layout wrap pa-2 v-else>
                <v-flex>
                  <span style="
                        font-family: montserratMedium;
                        color: #000000;
                        font-size: 14px;
                      ">No public files ...</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["asset"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      msg: null,
      timeout: 5000,
      tok: localStorage.getItem("token"),
    };
  },
};
</script>
<style>
.v-data-table-header {
  background-color: #c3e5ff;
  font-size: 20px;
  color: black;
}

.v-data-table .v-data-table-header tr th {
  font-size: 15px !important;
  color: black;
  font-family: montserratMedium;
}
</style>