<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#7CB9E8" top :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center" style="font-size: 18px; font-family: montserratSemiBold">{{ msg
        }}</v-flex>
        <v-flex text-right>
          <v-btn :ripple="false" text @click="showSnackBar = false">
            <span style="font-size: 18px; font-family: montserratExtraBold">Ok
            </span>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center py-5>
      <v-flex xs10 sm8 text-left>
        <v-layout wrap pb-4>
          <v-expansion-panels flat v-model="panel" v-bind:style="{ border: '1px solid #7CB9E8' }">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-flex xs10>
                  <span style="
                                    font-family: sedanRegular;
                                    color: #000000;
                                    font-size: 18px;
                                  ">Asset Details</span>
                </v-flex>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap justify-start pa-4>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Asset ID</span>
                      </v-flex>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.assetID }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Asset Type</span>
                      </v-flex>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.assetType }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs6 sm6 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Asset Name</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.assetName">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.assetName }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs6 sm6 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Transaction Type</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.transactionType">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.transactionType }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <!-- <v-flex xs12 sm12 pt-2 v-if="asset.transactionType=='Transfer'">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span
                          style="
                            font-family: montserratSemiBold;
                            color: #000000;
                            font-size: 14px;
                          "
                          >Transfered To</span
                        >
                      </v-flex>
                      <v-flex xs12>
                        <span
                          style="
                            font-family: montserratMedium;
                            color: #000000;
                            font-size: 14px;
                          "
                          >{{ asset.ownerUid.name }}</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex> -->
                  <v-flex xs12 sm6 pt-2 v-if="asset.ownerUid">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Owner</span>
                      </v-flex>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.ownerUid.name }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2 v-if="asset.previousOwnerUid">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Previous Owner</span>
                      </v-flex>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.previousOwnerUid.name }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm12 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Asset Description</span>
                      </v-flex>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        " v-html="asset.description"></span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
        <v-layout wrap pb-4 v-if="asset.assetType == 'Units'">
          <v-expansion-panels flat v-bind:style="{ border: '1px solid #7CB9E8' }">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-flex xs10>
                  <span style="
                                    font-family: sedanRegular;
                                    color: #000000;
                                    font-size: 18px;
                                  ">Unit Details</span>
                </v-flex>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap justify-start pa-4>
                  <v-flex xs12 sm6 v-if="asset.unitHolderEntityName">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Unit Holder Entity Name</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.unitHolderEntityName">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.unitHolderEntityName }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <!-- <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span
                          style="
                            font-family: montserratSemiBold;
                            color: #000000;
                            font-size: 14px;
                          "
                          >Unit Holder Code</span
                        >
                      </v-flex>
                      <v-flex xs12 v-if="asset.unitHolderCode">
                        <span
                          style="
                            font-family: montserratMedium;
                            color: #000000;
                            font-size: 14px;
                          "
                          >{{ asset.unitHolderCode }}</span
                        >
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span
                          style="
                            font-family: montserratMedium;
                            color: #000000;
                            font-size: 14px;
                          "
                          >---</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex> -->
                  <v-flex xs12 sm6 pt-2 v-if="asset.unitsTotalSupply">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Units Total Supply</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.unitsTotalSupply">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.unitsTotalSupply.toLocaleString() }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2 v-if="asset.unitsBlocked">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Units Blocked</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.unitsBlocked">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.unitsBlocked.toLocaleString() }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2 v-if="asset.unitsBuffered">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Units Buffered</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.unitsBuffered">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.unitsBuffered.toLocaleString() }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2 v-if="asset.creationDate">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Creation Date</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.creationDate">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.creationDate.slice(0, 10) }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2 v-if="asset.expiryDate">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Expiry Date</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.expiryDate">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.expiryDate.slice(0, 10) }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2 v-if="asset.transferType">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Transfer Type</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.transferType">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.transferType }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2 v-if="asset.transferType == 'Sale'">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Units Transfered To</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.toId">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.toId.name }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2 v-if="asset.transferType == 'Purchase'">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Units Transfered From</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.fromId">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.fromId.name }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2 v-if="asset.noOfUnits">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Number of Units Transfered</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.noOfUnits">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.noOfUnits.toLocaleString() }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2 v-if="asset.unitsBalance">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Units Balance</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.unitsBalance">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.unitsBalance.toLocaleString() }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2 v-if="asset.unitsSold">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Total Units Sold</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.unitsSold">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.unitsSold.toLocaleString() }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2 v-if="asset.heldFrom">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Held From</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.heldFrom">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.heldFrom.slice(0, 10) }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2 v-if="asset.heldTo">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Held To</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.heldTo">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.heldTo.slice(0, 10) }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
        <v-layout wrap pb-4 v-if="asset.assetType == 'Land'">
          <v-expansion-panels v-if="
            asset.transactionType == 'Register' ||
            asset.transactionType == 'Update'
          " flat v-bind:style="{ border: '1px solid #7CB9E8' }">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-flex xs10>
                  <span style="
                                    font-family: sedanRegular;
                                    color: #000000;
                                    font-size: 18px;
                                  ">Land Details</span>
                </v-flex>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap justify-center pa-4>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Address</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.address">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.address }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">City</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.city">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.city }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">State</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.state">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.state }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Postcode</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.postCode">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.postCode }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Title number</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.titleNumber">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.titleNumber }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Survey number</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.surveyNumber">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.surveyNumber }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Sub division number</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.subDivisionNumber">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.subDivisionNumber }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Land value</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.landValue">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.landValue }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm12 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Plot size (sq.m)</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.plotSize">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.plotSize }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
        <v-layout wrap pb-4 v-if="asset.assetType == 'Land'">
          <v-expansion-panels v-if="
            asset.transactionType == 'Register' ||
            asset.transactionType == 'Update'
          " flat v-bind:style="{ border: '1px solid #7CB9E8' }">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-flex xs10>
                  <span style="
                                    font-family: sedanRegular;
                                    color: #000000;
                                    font-size: 18px;
                                  ">Location</span>
                </v-flex>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap justify-center pa-4>
                  <v-flex xs12 pb-4>
                    <span style="
                                      font-family: montserratSemiBold;
                                      color: #000000;
                                      font-size: 16px;
                                    ">GPS Location</span>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Lattitude</span>
                      </v-flex>
                      <v-flex xs12 v-for="(item, i) in asset.gpsLocationCodes" :key="i">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ item.location[1] }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Longitude</span>
                      </v-flex>
                      <v-flex xs12 v-for="(list, j) in asset.gpsLocationCodes" :key="j">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ list.location[0] }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
        <v-layout wrap pb-4 v-if="asset.assetType == 'Land'">
          <v-expansion-panels v-if="
            asset.transactionType == 'Register' ||
            asset.transactionType == 'Update'
          " flat v-bind:style="{ border: '1px solid #7CB9E8' }">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-flex xs10>
                  <span style="
                                    font-family: sedanRegular;
                                    color: #000000;
                                    font-size: 18px;
                                  ">Project Details
                  </span>
                </v-flex>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap justify-center pa-4>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Project Start Date</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.projectStartDate">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.projectStartDate.slice(0, 10) }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Project End Date</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.projectEndDate">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.projectEndDate.slice(0, 10) }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm12 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Project manager</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.projectManager">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.projectManager }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
        <v-layout wrap pb-4 v-if="asset.assetType == 'Land'">
          <v-expansion-panels v-if="
            asset.transactionType == 'Register' ||
            asset.transactionType == 'Update'
          " flat v-bind:style="{ border: '1px solid #7CB9E8' }">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-flex xs10>
                  <span style="
                                    font-family: sedanRegular;
                                    color: #000000;
                                    font-size: 18px;
                                  ">Owner Details
                  </span>
                </v-flex>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap justify-center pa-4>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Owner Name</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.ownerName">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.ownerName }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Owner Business Registration number
                        </span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.ownerBusinessRegistrationNumber">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.ownerBusinessRegistrationNumber }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm12 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Owner Individual ID (government issued)</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.ownerIndividualID">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.ownerIndividualID }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>

        <v-layout wrap pb-4 v-if="asset.assetType == 'Land'">
          <v-expansion-panels v-if="
            asset.transactionType == 'Register' ||
            asset.transactionType == 'Update'
          " flat v-bind:style="{ border: '1px solid #7CB9E8' }">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-flex xs10>
                  <span style="
                                    font-family: sedanRegular;
                                    color: #000000;
                                    font-size: 18px;
                                  ">Lease Ownership</span>
                </v-flex>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap justify-center pa-4>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Owned or Leased (0/L)</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.ownedOrLeased">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.ownedOrLeased }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Lease monthly amount</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.leaseMonthlyAmount">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.leaseMonthlyAmount }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Lease term start</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.leaseTermStart">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.leaseTermStart.slice(0, 10) }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Lease term end</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.leaseTermEnd">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.leaseTermEnd.slice(0, 10) }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm12 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Lease Comments</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.leaseComments">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.leaseComments }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
        <v-layout wrap pb-4 v-if="
          asset.transactionType == 'Register' ||
          asset.transactionType == 'Update'
        ">
          <v-expansion-panels flat v-bind:style="{ border: '1px solid #7CB9E8' }">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-flex xs10>
                  <span style="
                                    font-family: sedanRegular;
                                    color: #000000;
                                    font-size: 18px;
                                  ">Owner Bank Account Details (Private)
                  </span>
                </v-flex>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap justify-center pa-4>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Owner Bank Name</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.ownerBankName">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.ownerBankName }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Owner Bank Branch</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.ownerBankBranch">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.ownerBankBranch }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm12 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Owner Bank Account Number</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.ownerBankAccountNumber">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.ownerBankAccountNumber }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
        <v-layout wrap pb-4 v-if="asset.assetType == 'Land'">
          <v-expansion-panels v-if="
            asset.transactionType == 'Register' ||
            asset.transactionType == 'Update'
          " flat v-bind:style="{ border: '1px solid #7CB9E8' }">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-flex xs10>
                  <span style="
                                    font-family: sedanRegular;
                                    color: #000000;
                                    font-size: 18px;
                                  ">Species</span>
                </v-flex>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap justify-center pa-4>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Flora</span>
                      </v-flex>
                      <v-flex xs12 v-for="(flora, f) in asset.speciesFlora" :key="f">
                        <span v-if="flora" style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ flora }}</span>
                        <span v-else style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Fauna</span>
                      </v-flex>
                      <v-flex xs12 v-for="(fauna, a) in asset.speciesFauna" :key="a">
                        <span v-if="fauna" style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ fauna }}</span>
                        <span v-else style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm12 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Other</span>
                      </v-flex>
                      <v-flex xs12 v-for="(other, o) in asset.speciesOther" :key="o">
                        <span v-if="other" style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ other }}</span>
                        <span v-else style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm12 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Additional Comments</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.speciesAdditionalComments">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.speciesAdditionalComments }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["asset"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      msg: null,
      timeout: 5000,
      panel: 0,
    };
  },

};
</script>
<style>
.v-data-table-header {
  background-color: #c3e5ff;
  font-size: 20px;
  color: black;
}

.v-data-table .v-data-table-header tr th {
  font-size: 15px !important;
  color: black;
  font-family: montserratMedium;
}
</style>