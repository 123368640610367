var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"800px"}},[_c('PageLoader',{attrs:{"storage":_vm.appLoading}}),(_vm.ServerError)?_c('ServerError'):_vm._e(),_c('v-snackbar',{attrs:{"color":"#7CB9E8","top":"","timeout":_vm.timeout},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{staticClass:"align-self-center",staticStyle:{"font-size":"18px","font-family":"montserratSemiBold"},attrs:{"text-left":""}},[_vm._v(_vm._s(_vm.msg))]),_c('v-flex',{attrs:{"text-right":""}},[_c('v-btn',{attrs:{"ripple":false,"text":""},on:{"click":function($event){_vm.showSnackBar = false}}},[_c('span',{staticStyle:{"font-size":"18px","font-family":"montserratExtraBold"}},[_vm._v("Ok ")])])],1)],1)],1),_c('v-layout',{attrs:{"wrap":"","justify-center":"","py-5":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","text-left":""}},[_c('v-layout',{attrs:{"wrap":"","pb-16":""}},[_c('v-flex',{attrs:{"xs9":"","sm10":"","px-5":""}},[_c('span',{staticStyle:{"font-family":"sedanRegular","color":"#000000","font-size":"18px"}},[_vm._v("Public Files")])]),_c('v-spacer'),(_vm.asset.publicFiles)?_c('v-flex',{attrs:{"xs3":"","sm2":""}},[_c('a',{staticClass:"downlink",staticStyle:{"text-decoration":"none"},attrs:{"target":"_blank","download":"","href":_vm.baseURL +
            '/asset/publicFiles/view?id=' +
            _vm.asset._id +
            '&token=' +
            _vm.tok}},[_c('span',{staticStyle:{"font-family":"sedanRegular","color":"black"}},[_vm._v("Download "),_c('v-icon',{attrs:{"color":"#7CB9E8"}},[_vm._v("mdi-download")])],1)])]):_vm._e(),_c('v-layout',{attrs:{"wrap":"","justify-center":"","pa-4":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","pb-5":""}},[(_vm.asset.publicFilesHashes.length > 0)?_c('v-layout',{attrs:{"wrap":""}},_vm._l((_vm.asset.publicFilesHashes),function(publicName,p){return _c('v-flex',{key:p,attrs:{"xs12":"","pa-1":""}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-layout',{attrs:{"wrap":"","pa-2":""}},[_c('v-flex',{attrs:{"xs2":"","sm2":"","pt-1":""}},[_c('span',{staticStyle:{"font-family":"montserratSemiBold","color":"#000000","font-size":"14px"}},[_vm._v("File Name :")])]),_c('v-flex',{attrs:{"xs10":"","sm10":"","text-left":""}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticStyle:{"background-color":"#d6d1c3"},attrs:{"xs10":"","md11":"","text-left":"","pa-2":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"poppinssemibold"},style:({
                                  'font-size':
                                    _vm.$vuetify.breakpoint.name == 'xs'
                                      ? '10px'
                                      : _vm.$vuetify.breakpoint.name == 'sm'
                                        ? '13px'
                                        : _vm.$vuetify.breakpoint.name == 'md'
                                          ? '10px'
                                          : _vm.$vuetify.breakpoint.name == 'lg'
                                            ? '12px'
                                            : '12px',
                                })},[_vm._v(" "+_vm._s(publicName.fileName))])])],1)],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-flex',{attrs:{"xs2":"","sm1":"","md1":"","text-center":"","align-self-center":""}},[_c('v-icon',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.copyImageUrl2(p)}}},[_vm._v(" mdi-checkbox-multiple-blank-outline ")]),_c('input',{attrs:{"type":"hidden","id":'testing-code2' + p},domProps:{"value":publicName.fileName}})],1)],1)],1)],1)],1),_c('v-layout',{attrs:{"wrap":"","pa-2":""}},[_c('v-flex',{attrs:{"xs2":"","sm2":"","pt-1":""}},[_c('span',{staticStyle:{"font-family":"montserratSemiBold","color":"#000000","font-size":"14px"}},[_vm._v("Hash (SHA256) :")])]),_c('v-flex',{attrs:{"xs10":"","sm10":"","text-left":""}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticStyle:{"background-color":"#d6d1c3"},attrs:{"xs10":"","md11":"","text-left":"","pa-2":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"poppinssemibold"},style:({
                                  'font-size':
                                    _vm.$vuetify.breakpoint.name == 'xs'
                                      ? '10px'
                                      : _vm.$vuetify.breakpoint.name == 'sm'
                                        ? '13px'
                                        : _vm.$vuetify.breakpoint.name == 'md'
                                          ? '10px'
                                          : _vm.$vuetify.breakpoint.name == 'lg'
                                            ? '12px'
                                            : '12px',
                                })},[_vm._v(" "+_vm._s(publicName.fileHash))])])],1)],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-flex',{attrs:{"xs2":"","sm1":"","md1":"","text-center":"","align-self-center":""}},[_c('v-icon',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.copyImageUrl3(p)}}},[_vm._v(" mdi-checkbox-multiple-blank-outline ")]),_c('input',{attrs:{"type":"hidden","id":'testing-code3' + p},domProps:{"value":publicName.fileHash}})],1)],1)],1)],1)],1)],1)],1)}),1):_c('v-layout',{attrs:{"wrap":"","pa-2":""}},[_c('v-flex',[_c('span',{staticStyle:{"font-family":"montserratMedium","color":"#000000","font-size":"14px"}},[_vm._v("No public files ...")])])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }